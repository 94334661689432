import React from 'react';


export default class CallToAction extends React.Component {

    render(){ 

        const cta = this.props.cta;
        const link = cta.link;
        const label = cta.label;
        const iconType = cta.icon;

        return (
            <a className="cta" href={link}>{label}<img className="icon" src={require('../assets/images/icons/'+ iconType +'.png')} alt="icon"/></a>
        )
    }
};