import React from 'react';

export default class EmployeeInfo extends React.Component {
    render(){

        const accountInfo = this.props.accountInfo;

        return (
            <li className="user-info"><div className="user-greeting">Hello, {accountInfo.account.name} </div><div className="link-container"><button onClick={this.props.logout} >Log Out</button></div></li>
        ) 
    }
}
