import React from 'react';
import CopyRight from './Copyright';


export default class SplashScreen extends React.Component {
    state = this.props.state

    constructor(props){
        super(props);
        this.state = {
            loaded : false
        }
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({
                loaded : true,
            })
        }, 1000)
    }
    
    render(){

        // console.log(this.state.loaded);

        var opacity = (this.state.loaded) ? "0" : "1";
        var zindex = (this.state.loaded) ? "0" : "9999999999";
    

        return (
            <div className="splashscreen" style={{
                opacity : opacity,
                zIndex : zindex,
                transition : "750ms ease-in-out"
            }}>

                <div className="logo-container">
                    <img className="logo" src={require('../assets/images/logo.png')} alt="Victaulic" ></img>
                </div>
                <CopyRight />
            </div>
        )
    }
}