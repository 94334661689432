import React from 'react';

const drawerToggleButton = props => {

    let buttonClasses = 'toggle-button';

    if(props.toggle) {
        buttonClasses = 'toggle-button toggle';
    }
    
    return (
        <button className={buttonClasses} onClick={props.click}>
            <div className="toggle_button_line top" />
            <div className="toggle_button_line center" />
            <div className="toggle_button_line bottom" />
        </button>
    );
}

export default drawerToggleButton;