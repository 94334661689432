import React from 'react';
import Section from './Section';

export default class Dashboard extends React.Component {

    render() { 

        const dashboard = this.props.dashboard;

        var scroll = (this.props.toggle) ? "scroll" : "hidden";

        return (
            <div className="dash-container" style={{scroll}}>
                {
                    dashboard.sections.map((section, sectionIndex) => {
                        return <Section section={section} index={sectionIndex} key={section.id} logout={this.props.logout}/>
                    })
                }
            </div>
        )
    }
}