import React from 'react';
import Module from './Module';

export default class Section extends React.Component {
    render() {
        
        const section = this.props.section;
        const title = section.title;
        const modules = section.modules;
        var slug = title.toLowerCase();
        slug = slug.replace(/\s/g, '');

        return (
            <div className="section-container" >
                <div className="anchor" id={slug} />
                <h1 className="section-title" >
                    {title}
                </h1>
                {
                    modules.map((module, moduleIndex) => {
                        return <Module 
                            module={module} 
                            index={moduleIndex} 
                            key={module.id} 
                        />
                    })
                }
            </div>
        )
    }
}