import React from 'react';
import CopyRight from './Copyright';


export default class LoadingScreen extends React.Component {
    
    render(){    

        return (
            <div className="splashscreen loading" style={{
                opacity : "1",
                zIndex : "9999999999",
                transition : "750ms ease-in-out"
            }}>

                <div className="logo-container">
                    <img className="logo" src={require('../assets/images/logo.png')} alt="Victaulic" ></img>
                    <h1>Please sign in to continue</h1>
                </div>
                
                <CopyRight />
                 
            </div>
        )
    }
}