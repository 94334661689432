import React from 'react';
import UserInterface from './UserInterface';
import Authentication from './Authentication';
import Copyright from './Copyright';

export default class WelcomeScreen extends React.Component {
    state = this.props.state
    
    handleClickEmployee() {
        this.setState({
            loaded : true, //CHANGE TO FALSE FOR LOADING SCREEN!!!!!!!! 
            loggedIn: true,
            userName: "Employee",
            userType : "employee_dashboard"
        })
        this.setState({ state: this.state });

        
    }

    handleClickCustomer() {
        this.setState({
            loggedIn: true,
            userName: "Customer",
            userType : "customer_dashboard"
        })
        this.setState({ state: this.state });        
    }

    render(){

        if(this.state.loggedIn) {
            let condition;
            if(this.state.userType === "customer_dashboard") {
                condition = <UserInterface state={this.state} dashData={this.props.dashData}/>
            } else {
                condition = <Authentication state={this.state} dashData={this.props.dashData}/>
            }

            return condition;
        }
        

        const options = this.props.dashData.options.welcome_screen;
        const title = options.title
        const sub_text = options.sub_text
        
        return(
            <div className="welcome-screen">
                <div className="ws-content" >
                    <div className="logo-container">
                        <img className="logo" src={require('../assets/images/logo.png')} alt="Victaulic" ></img>
                    </div>
                    <div className="content-container" >
                        <h1>{title}</h1>
                        <p>
                            {sub_text}
                        </p>
                    </div>
                    <div className="login-cta-container">
                        <button  className="welcome-screen-cta" onClick={(e) => this.handleClickEmployee(e)}>
                            I'm a Victaulic <strong>Employee</strong>
                        </button>
                        <button className="welcome-screen-cta" onClick={(e) => this.handleClickCustomer(e)}>
                            I'm a <strong>Customer / Distributor</strong>
                        </button>
                    </div>
                    <Copyright />
                </div>
            </div>
        )
    }
}