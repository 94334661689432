import React from 'react';
import CopyRight from './Copyright';
import CallToAction from './CallToAction';


export default class Footer extends React.Component {

    render(){
        const footer = this.props.footer;
        return(
            <div className="footer-container"  >
                {
                    footer.ctas.map((cta, ctaIndex) => {
                        return <CallToAction cta={cta} icons={footer.icons} index={ctaIndex} key={cta.id}  />;
                    })
                }
                <CopyRight />
            </div>
        )
    }

}