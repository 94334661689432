import React from "react";
import { AzureAD, AuthenticationState } from 'react-aad-msal'; 
import UserInterface from './UserInterface';
import LoadingScreen from './LoadingScreen';
import { authProvider } from './authProvider';

export default class Authentication extends React.Component {


    render(){


        const dashData = this.props.dashData;
        const state = this.props.state;


        return (
            <AzureAD provider={authProvider} forceLogin={true} >
                {
                    ({login, logout, authenticationState, error, accountInfo}) => {
                    if (authenticationState === AuthenticationState.Authenticated) {
                        return (
                            <UserInterface dashData={dashData} 
                                state={state} 
                                login={login}
                                logout={logout}
                                authenticationState={authenticationState}
                                error={error}
                                accountInfo = {accountInfo} 
                            /> 
                        );
                    } else if (authenticationState === AuthenticationState.Unauthenticated) {
                        if (error) {
                            return (
                                <p>
                                    <span>An error occured during authentication, please try again!</span>
                                    <button style={{color: "white"}} onClick={login}> Login</button> 
                                </p>
                                );
                            }
                    
                            return (
                                <LoadingScreen />
                            );
                        }
                    }
                }
                
            </AzureAD>
        )
    }
}

// serviceWorker.unregister();


