import React from 'react';
import ReactDOM from 'react-dom';
import WelcomeScreen from './components/WelcomeScreen';
import data from './data/data';
import SplashScreen from './components/SplashScreen';
// import { runWithAdal } from 'react-adal';
// import { authContext } from './adalConfig';
import './assets/scss/style.css'; 

const dashData = data;

class App extends React.Component {

    state = {
        sideDrawerOpen : false,
        loaded : false,
        loggedIn : false,
        userName : "",
        userType : ""

    }

    

    splashScreen = () => {
        if(!this.state.loaded) {
            if(!this.state.loaded) {
                return (
                    <div>
                        <SplashScreen state={this.state} loaded={this.state.loaded} />
                        <WelcomeScreen state={this.state} dashData={dashData} />
                    </div>
                ) 
            }
        }
    }    

    render() {
        
        return (
            <div className="dashboard" style={{height: '100%'}}>
                {this.splashScreen()}
            </div>
        )
    } 
}

ReactDOM.render(<App />, document.getElementById('root'));