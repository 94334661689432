import React from 'react';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';

const Toolbar = props => (
    <header className="toolbar">
 
        <nav className="toolbar_nav">
            <div className="drawer-toggle-btn">
                <DrawerToggleButton click={props.drawerClickHandler} toggle={props.toggle}/>
            </div>
            <div className="toolbar_logo">
                <a href="/">
                    <img src={require('../../assets/images/logo.png')} alt="Victaulic"/>
                </a>
            </div>
            <div className="toolbar_nav-items">
                {/* <ul>
                    <li><a href="/">Technical Refrences</a></li>
                    <li><a href="/">Product Support</a></li>
                    <li><a href="/">Technical Support</a></li>
                </ul> */}
            </div>

        </nav>

    </header>
);

export default Toolbar;