import React from 'react';


export default class Footer extends React.Component {
    render() {
        return (
            <div className="copyright">
                ©{(new Date().getFullYear())} Victaulic. All Rights Reserved. <a href="/">Terms of Use</a>
            </div>
        )
    }

}