// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/common',
        clientId: '3345dfd8-a94e-494f-9e20-7de71b5c950d',
        redirectUri: 'https://www.victaulicmobile.com/'
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

const authenticationParameters = {
    scopes: [
        'User.Read', 'Mail.Send', 'Profile'
    ]
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, LoginType.Redirect);