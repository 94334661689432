import React from 'react';


function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
}

export default class Module extends React.Component {
    render() {

        const module = this.props.module;
        const title = module.title;
        const content = module.content;
        const ctaType = module.cta_type;
        const ctaLink = module.cta_link;
        const icon = module.icon;

        console.log(icon)

        const userOS = getOS();

        var store = (userOS === 'Mac OS' || userOS === 'iOS') ? ' apple' : ' notApple';
        var cta = (ctaType === 'cta') ? 'btn-cta' : 'btn-play' + store;

        var notApple = <div className="store-btn"><img src={require('../assets/images/playstore.png')} alt="Google Play Store" /></div>
        var apple =  <div className="store-btn"><img src={require('../assets/images/appstore.png')} alt="Apple App Store"/></div>

        const btnShare = <button className={cta}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg><span>Launch</span></button>;

        const btnStore = (userOS === 'Mac OS' || userOS === 'iOS') ? apple : notApple;

        cta = (ctaType === 'cta') ? btnShare : btnStore;

        return (
            <div className="module-container" >
                <div className="icon-container" >
                    <div className="icon" > 
                        <img src={require('../assets/images/icons/'+ icon +'.png')} alt="icon"/>
                    </div>
                </div>
                <div className="content-container" >
                    <div className="module-title">
                        {title}
                    </div>
                    <div className="module-content" >
                        {content}
                    </div>
                    <div className="module-cta" >
                        <a href={ctaLink} target="_blank" rel="noopener noreferrer">
                            {cta}
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}