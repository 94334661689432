import React from 'react';
import Toolbar from './Toolbar/Toolbar';
import SideDrawer from './SideDrawer/SideDrawer';
import Dashboard from './Dashboard';
import Footer from './Footer';


export default class UserInterface extends React.Component {

    state = this.props.state;
    
    drawerToggleClickHandler = () => {
        this.setState((prevState) => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen}
        });
    };
    
     
    render(){
    
        const userType = this.state.userType;
        const toggle = this.state.sideDrawerOpen;
        const drawerClickHandler = this.drawerToggleClickHandler;
        const logout = this.props.logout;
        const accountInfo = this.props.accountInfo;
        const dashboard = this.props.dashData.dashboard[userType];
        const footer = dashboard.footer;

        return(
            <div className="UserInterface" >
                <Toolbar drawerClickHandler={drawerClickHandler} toggle={toggle} />
                <SideDrawer 
                    show={toggle} 
                    userType={userType} 
                    accountInfo={accountInfo} 
                    dashboard={dashboard} 
                    toggle={toggle} 
                    drawerClickHandler={drawerClickHandler}
                    logout={logout}
                    dashData={this.props.dashData}
                    state = {this.state}
                />
                <Dashboard dashboard={dashboard} toggle={toggle} logout={logout}/>
                <Footer footer={footer} />
            </div>
        )
    }
}