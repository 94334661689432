import React from 'react';
import Copyright from '../Copyright';
import CustomerInfo from './CustomerInfo';
import EmployeeInfo from './EmployeeInfo';

const sideDrawer = props => {



    let drawerClasses = (props.show) ? "side-drawer open" : "side-drawer"
    const accountInfo = props.accountInfo
    const userType = props.userType;
    const dashboard = props.dashboard; 
    const logout = props.logout;
    const dashData = props.dashData;
    const state = props.state;


    let userInfo = (userType === "customer_dashboard") ? <CustomerInfo state={state} dashData={dashData} /> : <EmployeeInfo accountInfo={accountInfo} logout={logout}/>; 

    return(
        <nav className={drawerClasses}>
            <ul className="drawer-container">
                {userInfo}
                {
                    dashboard.sections.map((section, index) => {
                        var title = section.title;
                        var slug = title.toLowerCase();
                        slug = slug.replace(/\s/g, '');
                        slug = "#" + slug;
                        return (
                            <li key={index} className="section-link"><a href={slug} onClick={props.drawerClickHandler} >{section.title}</a></li>
                        )
                    })
                }
                <li className="section-link contact"><a href="https://www.victaulic.com/technical-questions/" >Contact Us</a></li>
            </ul>
            <div className="drawer-visit">
                Visit <a href="http://victaulic.com" target="_blank" rel="noopener noreferrer">victaulic.com</a>
            </div>
            <div className="drawer-footer">
                <div className="footer-logo">
                    <img src={require('../../assets/images/logo.png')} alt="Victaulic"/> 
                </div>
                <Copyright />
            </div>
        </nav>
    );
}

export default sideDrawer;