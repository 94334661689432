import React from 'react';
import Authentication from '../Authentication';



export default class CustomerInfo extends React.Component {
    state = {
        show_auth : false,
    }

    prevState = this.props.state;
    dashData = this.props.dashData

    clickHandler = () => {
        this.setState({
            show_auth : true,
        })
    }

    renderAuth = (props) => {
        if(this.state.show_auth) {
            return (
                <Authentication state={this.prevState} dashData={this.dashData}/>
            )
        }
    }

    render(){

        

        return (
            <div>
                <li  className="user-info"><div className="link-container"><a href="/" onClick={this.clickHandler}>Login as Employee</a></div></li>
            </div>
        )
    }
}